import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const PrivacyPageTemplate = () => (
  <main role="main" className="privacy-page">
    <Helmet>
      <title>Privacy</title>
    </Helmet>
    {/* Begin Treatment and care */}
    <div className="about-main">
      <div className="inner-wrap">
        <div className="legal text-center" style={{"backgroundImage": `linear-gradient(90deg, rgba(146, 43, 184, 0.69), rgba(246, 31, 128, 0.69) , rgba(254, 164, 120, 0.69)), url(/assets/newimg/1280.png)`}}>
          <h1 className="text-white">Privacy Policy</h1>
        </div>
        <div className="legal-wrap bg-gray pb-5">
          <div className="container">
            <div className="row legal-row mb-5">
              <div className="col-md-12 p-5 ca-shadow-sm rounded bg-white">
                <p>
                  PeteHealth is committed to respecting the privacy and recognizing the need for appropriate protection and management of our users’ personal information of the kulagy mobile application (the “Application”) and services provided by us through the Application and our website at kulagy.com (collectively, the “Services”). When we do need your personal information to provide services that you request or when you choose to provide us with your personal information, this policy describes how we collect and use your personal information.
                </p>
                <h2>Acceptance</h2>
                <p>You are bound by the terms of this Privacy Policy and consent to the collection, use, and disclosure of visitor information pursuant to all of the terms of this Privacy Policy. By using the Website, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, you should not use our Website.</p>
                <h2>Information Collection</h2>
                <p>
                  Personal information means any information that may be used to identify an individual, including, but not limited to, a first and last name, email address, a home, postal or other physical address, other contact information, title, birth date, gender, occupation, industry, personal interests, Internet Protocol (IP) address and domain name used to access our portal, date and time you visited our website and pages visited, or other information when needed to provide a service you requested.
                  <br />
                  When you browse our website, you do so anonymously. We don’t collect personal information, including your email address, unless you sign up or register in the Application or our website for a kulagy account, submit your personal information to us for any other reason, or contact us for additional services or information or to resolve concerns.
                  <br />
                  We do log your IP address (the Internet address of your computer) to give us an idea of which part of our website you visit and how long you spend there. Like many other commercial apps and websites, the Application and our website may use cookies to collect information about how you use the site.
                  <br />
                  We do not access or store information that you have saved on your mobile device such as contacts and appointments. However, in order to provide our services, the Application or our website may interact with some features on your mobile device, such as your GPS locater. In such case, we will ask for your consent with respect to identifying your location.
                </p>
                <h2>
                  Notification
                </h2>
                <p>We will always give you the opportunity to “opt out” of receiving direct marketing or market research information. This means we assume you have given us your consent to collect and use your information in accordance with this Privacy Policy unless you take affirmative action to indicate that you do not consent, for instance by clicking or checking the appropriate option or box at the point of collection, or by contact us at support@petehealth.com. Please allow us a reasonable period of time in order to satisfy your request, as some promotions may already be in progress.
                  <br />
                  We may use the e-mail and text addresses you provide when you register to send appointment confirmations and reminders. You cannot opt out from receiving these email and text messages.
                </p>
                <h2>
                  Cookies
                </h2>
                <p>
                  Our Application may use cookies and other technologies to collect information. A cookie is a small data file that certain websites write to your hard drive when you visit them. This information allows us to customize your preferences and to deliver a better service for you. For example, the Application or website may use Cookies to save and remember search preferences that you may have done while browsing, in order to deliver content specific to your interests.
                  <br />
                  A cookie file can contain information such as a user ID that the site uses to track the pages you’ve visited, but the only personal information a cookie can contain is information you supply yourself. A cookie can’t read data off your hard disk or read cookie files created by other sites. Some parts of the Application and our website use cookies to understand user traffic patterns. We do this in order to determine the usefulness of our website information to our users and to see how effective our navigational structure is in helping users reach that information.
                  <br />
                  Cookies can contain pieces of Personal Information. These Cookies often are used to make the site easier to use. For example, if you check a box to ask that we remember your login information on your computer so that you don’t have to enter it each time you visit the site, it’s stored in a Cookie on your computer.
                  <br />
                  If you prefer not to receive cookies while browsing our website, you can set your browser to warn you before accepting cookies and refuse the cookie when your browser alerts you to its presence. You can also refuse all cookies by turning them off in your browser.
                </p>
                <h2>
                  Information Sharing and Disclosure
                </h2>
                <p>
                  Your personal information is never shared outside kulagy without your permission, except under conditions explained below. Inside kulagy, data is stored in controlled servers with limited access.
                  <br />
                  We do not track users, who do not interact with our sharing functionality across the web, and therefore we do not use “do not track” signals, even though various browsers offer a “do not track option.”
                  <br />
                  We don’t sell, rent, license or share any of the personally identifiable health information you provide to us with companies outside of the Company for their own promotional or marketing use without your explicit, affirmative consent. There are occasions, however, where we must share your information to provide you with the services you requested or for legal reasons. Descriptions of when we share and with whom we may share your information with are provided below.
                  <br />
                  We may send your personal information to other companies or people under any of the following circumstances: when we have your consent to share the information; we need to share your information with our staff, consultants, or companies that work with us, to provide the product or service you have requested (we will only provide those companies the information they reasonably need to deliver the service, and they are prohibited from using that information for any other purpose); or we want to keep you up to date on the latest product announcements, software updates, special offers or other information we think you’d like to hear about either from us or from our business partners. We will also disclose your personal information if required to do so by law, or in urgent circumstances, to protect personal safety, the public or the Application or our website.
                  <br />
                  If kulagy is sold to, or merges with, another company, we reserve the right to include your Personal Information among the assets transferred to the acquiring company. If so, the kulagy will seek to obligate the acquiring company to use any Personal Information transferred by this site in a manner consistent with this Privacy Policy, but cannot guarantee that it will be able to impose that requirement or that the acquiring company will comply.
                </p>
                <h2>
                  Disclosure of Aggregate Information
                </h2>
                <p>
                  We may combine any public and non-personal information you provide with information from other users to create aggregate data to display on the Website. This sort of statistical information is called aggregate data because it reflects the characteristics of a large group of anonymous people. Aggregate data does not contain any information that could be used to identify you or contact you. For example, we might inform third parties regarding the number of users of the Website and the activities they conduct while on our site. We may use aggregate data or share it with our business partners so that the information and services we provide best meet the needs of our users. Aggregate data also helps advertisers and sponsors know how effectively they are reaching and meeting the needs of their target audience.
                </p>
                <h2>
                  Control of Your Information
                </h2>
                <p>
                  If you are a registered kulagy user, you can review your personal information by going to the Application (or website, as the case may be) and signing in. You may also request deletion of your kulagy account or any of your personal information held by us by sending an email to support@petehealth.com.
                </p>
                <h2>
                  Data Security
                </h2>
                <p>
                  Your kulagy account information is password-protected for your privacy and security. We implement reasonable safeguards to protect the security of the data you send us through physical, administrative, and technical procedures. In certain areas of our websites, kulagy uses industry-standard SSL-encryption to enhance the security of data transmissions.
                  <br />
                  While we strive to protect your personal information, we cannot ensure the security of the information you transmit to us, and so we urge you to take every precaution to protect your personal data. No data transmission over the Internet or through mobile devices can be guaranteed to be 100% secure. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. It is your responsibility to protect the security of your login information. Change your passwords often and use a combination of letters and numbers.
                </p>
                <h2>
                  Children and Privacy
                </h2>
                <p>
                  The Application and our website do not target and are not intended to attract children under the age of 13. kulagy does not knowingly solicit personal information from children under the age of 13 or send them requests for personal information. If you are under 13 then you may not use or access our website or services at any time or in any manner.
                </p>
                <h2>
                  Third Parties
                </h2>
                <p>
                  Our Application and website may contain links to other websites or applications. kulagy does not share your personal information with those websites or applications and is not responsible for their privacy practices. Please note that when you click on any of these links, you are entering another website for which we have no responsibility or control. The inclusion of any link does not imply affiliation or endorsement by us of the linked website or any of the content, services or products offered on such sites. You access these sites at your own risk and we make no representations or warranties about the content, completeness, or accuracy of these links or the sites hyperlinked to this Web site, or about the privacy and security of information transmitted to or through them. We are simply a portal and do not have access to their data. You agree that we shall not be responsible for any loss or damage of any sort incurred as a result of any such links or as the result of the presence of such links on this site. We encourage you to learn about the privacy policies of those companies.
                </p>
                <h2>
                  Visitors of Website from Outside the United States
                </h2>
                <p>
                  The Company is governed by the laws of the United States of America. By using the Website and submitting any Personal Information, visitors from outside the United States acknowledge that the Website is subject to U.S. law, consent to the transfer of Personal Information to the U.S., and waive any claims that may arise under their own jurisdiction.
                </p>
                <h2>
                  Changes to Our Privacy Policy
                </h2>
                <p>
                  From time to time we may revise this Privacy Policy. If we make revisions that change the way we collect or use your personal information or medical information, those changes will be posted in this Privacy Policy and the effective date will be noted at the end of the Privacy Policy. Your continued use of this site following the posting of any changes to the Privacy Policy constitutes acceptance of those changes.
                </p>
                <h2>
                  How to Contact Us
                </h2>
                <p>
                  If you have questions or concerns about our collection, use, or disclosure of your personal information, please email support@petehealth.com or call (888) 859-0145.
                </p>
                <h2>
                  Last Updated
                </h2>
                <p>
                  This Privacy Policy was last updated January 8, 2021.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Treatement and care */}


  </main>
);


const PrivacyPage = () => {
  return (
    <Layout>
      <PrivacyPageTemplate/>
    </Layout>
  )
};

export default PrivacyPage;
